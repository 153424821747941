<template>
  <div id="app">
    <h1>Ajouter un prénom</h1>
    <form @submit.prevent="ajouterPrenom">
      <input type="text" v-model="nouveauPrenom" placeholder="Entrez un prénom" required>
      <button type="submit">Ajouter</button>
    </form>

    <h2>Liste des prénoms</h2>
    <ul>
      <li v-for="prenom in prenoms" :key="prenom.id">{{ prenom.prenom }}</li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      prenoms: [],
      nouveauPrenom: '' // Modèle pour le champ de formulaire
    }
  },
  mounted() {
    this.chargerPrenoms();
  },
  methods: {
    chargerPrenoms() {
      axios.get('https://filmetserie.fr/api/api.php')
        .then(response => {
          this.prenoms = response.data;
        })
        .catch(error => console.error('Erreur lors de la récupération des prénoms:', error));
    },
    ajouterPrenom() {
      const formData = new FormData();
      formData.append('prenom', this.nouveauPrenom);

      axios.post('https://filmetserie.fr/api/api.php', formData)
        .then(() => {
          this.nouveauPrenom = ''; // Réinitialiser le champ de formulaire
          this.chargerPrenoms(); // Recharger la liste pour afficher le nouveau prénom
        })
        .catch(error => console.error('Erreur lors de l\'ajout du prénom: ', error));
}

  }
}
</script>
